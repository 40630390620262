import styles from "./LandingCanvasViewer.module.scss";
import { useEffect, useState } from "react";
import { noHash } from "../../../utils/handleColor";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { LandingProduct } from "../../../types/Landing";
import { useLandingConfig } from "../../../providers/LandingConfigProvider";

const LandingCanvasViewer = () => {
  const {
    firaLandingConfiguration,
    layoutMode,
    existingLanding,
    landingConfig,
    isGlobalConfig,
  } = useLandingConfig();
  const [iframeSrc, setIframeSrc] = useState<string>("");
  const envUrl = process.env.REACT_APP_BASE_URL_LANDING;
  const { brand } = useSelector((state: RootState) => state);
  const mainStore = brand.list.find((item) => item.storeType === "MAIN_STORE");

  const urlSpaces = (url: string) => {
    return url.replace(" ", "%20");
  };

  const stringMapper = () => {
    let stringMap: LandingProduct[] = [];
    if (landingConfig && landingConfig.featuredProducts) {
      landingConfig.featuredProducts.map((product: LandingProduct) => {
        stringMap.push(product);
      });
      return encodeURIComponent(JSON.stringify(stringMap));
    }
  };

  useEffect(() => {
    setIframeSrc(`${envUrl}/?mode=0`);
  }, []);

  useEffect(() => {
    if (existingLanding !== null) {
      setIframeSrc(
        `${envUrl}${existingLanding.name}/?mode=0`.replace(/ /g, "")
      );
    }
  }, [existingLanding]);

  useEffect(() => {
    if (firaLandingConfiguration !== null && existingLanding !== null) {
      setIframeSrc(
        `${
          envUrl !== "https://liveshopping.firaonlive.com/"
            ? envUrl
            : "https://liveshopping.firaonlive.com/"
        }${
          existingLanding
            ? existingLanding.name
              ? existingLanding.name + "/"
              : "canvas/"
            : "canvas/"
        }?mode=0&firaKey=${
          isGlobalConfig ? mainStore?.id : landingConfig.storeId
        }&webLogoUrl=${
          firaLandingConfiguration ? firaLandingConfiguration.webLogoUrl : ""
        }&shoppingCart=${existingLanding.shoppingCart}&productCatalog=${
          existingLanding.productCatalog
        }&mobileLogoUrl=${
          firaLandingConfiguration && firaLandingConfiguration.mobileLogoUrl
            ? firaLandingConfiguration.mobileLogoUrl
            : ""
        }&headerHeight=${
          firaLandingConfiguration && firaLandingConfiguration.headerHeight
            ? firaLandingConfiguration.headerHeight
            : ""
        }&bgColor=${
          firaLandingConfiguration && firaLandingConfiguration.bgColor
            ? noHash(firaLandingConfiguration.bgColor)
            : ""
        }&headerColor=${
          firaLandingConfiguration && firaLandingConfiguration.headerColor
            ? noHash(firaLandingConfiguration.headerColor)
            : ""
        }&footerColor=${
          firaLandingConfiguration && firaLandingConfiguration.footerColor
            ? noHash(firaLandingConfiguration.footerColor)
            : ""
        }&logoAlignment=${
          firaLandingConfiguration && firaLandingConfiguration.logoAlignment
            ? firaLandingConfiguration.logoAlignment
            : "CENTER"
        }&fontColor=${
          firaLandingConfiguration && firaLandingConfiguration.widgetContent
            ? noHash(firaLandingConfiguration.widgetContent.fontColor)
            : ""
        }&displayColor=${
          firaLandingConfiguration && firaLandingConfiguration.widgetContent
            ? noHash(firaLandingConfiguration.widgetContent.displayColor)
            : ""
        }&bgColorW=${
          firaLandingConfiguration && firaLandingConfiguration.widgetContent
            ? noHash(firaLandingConfiguration.widgetContent.bgColor)
            : ""
        }&detailsColor=${
          firaLandingConfiguration && firaLandingConfiguration.widgetContent
            ? noHash(firaLandingConfiguration.widgetContent.detailsColor)
            : ""
        }&collectionId=${
          firaLandingConfiguration && firaLandingConfiguration.widgetContent
            ? firaLandingConfiguration.widgetContent.collectionId
            : ""
        }&sectionTitle=${
          firaLandingConfiguration && firaLandingConfiguration.widgetContent
            ? urlSpaces(firaLandingConfiguration.widgetContent.sectionTitle)
            : ""
        }&previousEvents=${
          firaLandingConfiguration && firaLandingConfiguration.widgetContent
            ? firaLandingConfiguration.widgetContent.previousEvents
            : ""
        }&fontSizeHeader=${
          firaLandingConfiguration
            ? firaLandingConfiguration.fontSizeHeader
            : ""
        }&fontSizeFooter=${
          firaLandingConfiguration
            ? firaLandingConfiguration.fontSizeFooter
            : ""
        }&fontSizeContent=${
          firaLandingConfiguration
            ? firaLandingConfiguration.fontSizeContent
            : ""
        }&fontHeaderWeight=${
          firaLandingConfiguration
            ? firaLandingConfiguration.fontHeaderWeight
            : ""
        }&fontFooterWeight=${
          firaLandingConfiguration
            ? firaLandingConfiguration.fontFooterWeight
            : ""
        }&fontContentWeight=${
          firaLandingConfiguration
            ? firaLandingConfiguration.fontContentWeight
            : ""
        }&fontHeader=${
          firaLandingConfiguration ? firaLandingConfiguration.fontHeader : ""
        }&fontFooter=${
          firaLandingConfiguration ? firaLandingConfiguration.fontFooter : ""
        }&fontContent=${
          firaLandingConfiguration ? firaLandingConfiguration.fontContent : ""
        }&widgetType=${
          firaLandingConfiguration && firaLandingConfiguration.widgetContent
            ? firaLandingConfiguration.widgetContent.widgetType
            : "VOD"
        }
        &landingId=${landingConfig.id ? landingConfig.id : ""}
`.replace(/ /g, "")
      );
    }
  }, [firaLandingConfiguration, landingConfig]);

  return (
    <>
      <div className={styles.LandingCanvasWrapper}>
        <div
          className={
            layoutMode === "MOBILE"
              ? styles.iframeWrapperMobile
              : styles.iframeWrapperDesktop
          }
        >
          {iframeSrc && <iframe src={iframeSrc}></iframe>}
        </div>
      </div>
    </>
  );
};

export default LandingCanvasViewer;
