import React, { Dispatch, SetStateAction } from "react";
import AgoraRTC, {
  ClientConfig,
  IAgoraRTCClient,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  UID,
} from "agora-rtc-sdk-ng";

import styles from "./AgoraPlayer.module.scss";

const rtcConfig: ClientConfig = {
  mode: "live",
  codec: "vp8",
};

const CLIENT_TYPE = "audience";

type LiveStreamParamsType = {
  // A variable to hold a remote audio track.
  remoteAudioTrack?: IRemoteAudioTrack;
  // A variable to hold a remote video track.
  remoteVideoTrack?: IRemoteVideoTrack;
  // A variable to hold the remote user id.s
  remoteUid: UID;
};

interface Props {
  setVideoExist: React.Dispatch<React.SetStateAction<boolean>>;
  subscriberData: { channel: string; appId: string; token: string };
  handlevideoPlayerRef: Dispatch<SetStateAction<HTMLVideoElement | null>>;
  isVideoMuted: boolean;
  // setIsVideoMuted:Dispatch<SetStateAction<boolean>,
}
export const AgoraPlayer: React.FC<Props> = ({
  setVideoExist,
  subscriberData,
  handlevideoPlayerRef,
  isVideoMuted,
}) => {
  const videoPlayerWrapperRef = React.useRef<HTMLDivElement>(null);
  const agoraClientRef = React.useRef<IAgoraRTCClient | null>(null);
  const [channelParams, setchannelParams] = React.useState<
    LiveStreamParamsType[]
  >([]);
  const [videoTracks, setVideoTracks] = React.useState<string[]>([]);

  const toggleMute = (isMuted: boolean) => {
    if (channelParams.length > 0) {
      if (isMuted) {
        channelParams.map(
          (channel) =>
            channel.remoteAudioTrack && channel.remoteAudioTrack.stop()
        );
      } else {
        channelParams.map(
          (channel) =>
            channel.remoteAudioTrack && channel.remoteAudioTrack.play()
        );
      }
    }
  };

  const connectToLiveStream = async () => {
    try {
      agoraClientRef.current = await AgoraRTC.createClient({
        mode: rtcConfig.mode,
        codec: rtcConfig.codec,
      });

      await agoraClientRef.current.setClientRole(CLIENT_TYPE, { level: 1 });

      await agoraClientRef.current.join(
        subscriberData.appId,
        subscriberData.channel,
        subscriberData.token
      );

      agoraClientRef.current.on("user-published", async (user, mediaType) => {
        switch (mediaType) {
          case "video":
            await agoraClientRef.current?.subscribe(user, mediaType);
            if (user.videoTrack && videoPlayerWrapperRef.current) {
              user.videoTrack.play(videoPlayerWrapperRef.current, {
                fit: "contain",
              });
              setVideoExist(true);
              const videoTrackId = user.videoTrack.getTrackId();
              if (videoTracks.length === 0) {
                handlevideoPlayerRef(
                  document.getElementById(
                    `video_${videoTrackId}`
                  ) as HTMLVideoElement
                );
              }

              setVideoTracks((prevState) => {
                return [...prevState, `video_${videoTrackId}`];
              });
            }
            break;
          case "audio":
            await agoraClientRef.current?.subscribe(user, mediaType);
            setchannelParams((prevState) => {
              return [
                ...prevState,
                {
                  remoteAudioTrack: user.audioTrack,
                  remoteUid: user.uid,
                },
              ];
            });
            break;
          default:
            break;
        }
      });
      agoraClientRef.current.on("user-unpublished", async (user, mediaType) => {
        switch (mediaType) {
          case "video":
            if (user.videoTrack) {
              const videoTrackId = user.videoTrack.getTrackId();
              const newState = videoTracks.filter(
                (vt) => vt !== `video_${videoTrackId}`
              );
              if (newState.length > 0) {
                handlevideoPlayerRef(
                  document.getElementById(
                    newState[newState.length - 1]
                  ) as HTMLVideoElement
                );
              }
              setVideoTracks(newState);
            }

            await agoraClientRef.current?.unsubscribe(user, mediaType);
            break;
          case "audio":
            await agoraClientRef.current?.unsubscribe(user, mediaType);
            setchannelParams((prevState) =>
              prevState.filter((ch) => ch.remoteUid !== user.uid)
            );
            break;
          default:
            break;
        }
      });
    } catch (error) {
      console.error("Failed to join the channel:", error);
    }
  };

  React.useEffect(() => {
    connectToLiveStream();
    return () => {
      if (agoraClientRef.current) {
        agoraClientRef.current.removeAllListeners();
        agoraClientRef.current.leave();
      }
    };
  }, []);

  React.useEffect(() => {
    toggleMute(isVideoMuted);
  }, [isVideoMuted]);

  return (
    <div ref={videoPlayerWrapperRef} className={styles.responsiveGrid}></div>
  );
};
